<template>
    <div class="container-table-coliseum body-table">
        <div
            class="d-flex align-items-center justify-content-center body-index"
        >
            <p>{{ index + 1 }}</p>
        </div>

        <div class="d-flex align-items-center justify-content-center body-cell">
            <p>{{ item.name }}</p>
        </div>

        <div class="d-flex align-items-center justify-content-center body-cell">
            <div class="container-tournament ">
                <div
                    v-if="item.phones.phone"
                    class="item-phone-contact"
                    @click="doCopy(item.phones.phone)"
                >
                    <feather-icon
                        class="text-primary icon-clip-modal-calendar"
                        icon="ClipboardIcon"
                        size="16"
                    />
                    <p class="tournament-link ">
                        {{ item.phones.phone }}
                    </p>
                </div>
                <div
                    v-if="item.phones.phone_aux1"
                    class="item-phone-contact"
                    @click="doCopy(item.phones.phone_aux1)"
                >
                    <feather-icon
                        class="text-primary icon-clip-modal-calendar"
                        icon="ClipboardIcon"
                        size="16"
                    />
                    <p class="tournament-link ">
                        {{ item.phones.phone_aux1 }}
                    </p>
                </div>
                <div
                    v-if="item.phones.phone_aux2"
                    class="item-phone-contact"
                    @click="doCopy(item.phones.phone_aux2)"
                >
                    <feather-icon
                        class="text-primary icon-clip-modal-calendar"
                        icon="ClipboardIcon"
                        size="16"
                    />
                    <p class="tournament-link ">
                        {{ item.phones.phone_aux2 }}
                    </p>
                </div>
            </div>
        </div>


        <div class="d-flex align-items-center justify-content-center body-cell">
            <p>{{ item.user_name }}</p>
        </div>

        <div class="d-flex align-items-center justify-content-center body-cell">
            <v-select
                :id="`${item.id}-id-select`"
                v-model="statusModel"
                :options="statusArray"
                :clearable="false"
                transition=""
                placeholder="Elija el ejemplar"
                @input="toggleStatus(statusModel, item.id)"
                :reduce="option => option.id"
            >
                <template #option="{ label, color }">
                    <div class="selected d-center d-flex align-items-center">
                        <div
                            class="circle"
                            :style="{ background: color }"
                        ></div>
                        <p>{{ label }}</p>
                    </div>
                </template>

                <template #selected-option="{ label,color }">
                    <div class="selected d-center d-flex align-items-center">
                        <div
                            class="circle"
                            :style="{ background: color }"
                        ></div>
                        <p>{{ label }}</p>
                    </div>
                </template>
            </v-select>
        </div>

        <div class="d-flex align-items-center justify-content-center body-cell">
            <edit-contact-brain
                @refreshModal="$emit('refreshModal')"
                :item="item"
            />

            <feather-icon
                @click="deleteContact(item.id, item.tournaments)"
                class="icon text-danger"
                icon="TrashIcon"
                :size="width < 600 ? '18' : '24'"
            />
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import contactService from "../contacts.service";
import EditContactBrain from "./EditContactBrain";
import { mapState } from 'vuex'

export default {
    name: "ListContactsBrain",
    props: ["item", "index"],
    components: { vSelect, EditContactBrain },
    
    data() {
        return {
            width: this.$store.state["app"].windowWidth,
            statusArray: [
                {
                    id: 1,
                    label: "Inactivo",
                    color: "#ea5455"
                },
                {
                    id: 2,
                    label: "Pendiente",
                    color: "#00d0e7"
                },
                {
                    id: 3,
                    label: "Activo",
                    color: "#28c66f"
                }
            ],
            statusModel: 1
        };
    },
    computed: {
        ...mapState("auth", ["currentUser"])
    },
    mounted() {
        this.statusModel = this.item.status;
    },
    methods: {
        async toggleStatus(status, id) {
            // const swal = await this.showConfirmSwal();
            // if (swal.isConfirmed) {

            // }
            const resp = await contactService.updateStatus({
                status: status,
                id
            });
            await this.$emit("refreshModal");
        },
        async deleteContact(id, itemsLenght) {
            if (itemsLenght.length > 0) {
                await this.showInfoSwal({
                    title: "Informacion",
                    text:
                        "¡Para eliminar un organizador antes debe eliminar sus torneos asociados!"
                });
            } else {
                const swal = await this.showConfirmSwal();
                if (swal.isConfirmed) {
                    const resp = await contactService.deleteContactById({
                        user_id: this.currentUser.user_id, 
                        id
                    });
                    await this.$emit("refreshModal");
                }
            }
        },
        toTournament(id) {
            window.open(`/torneos/detalle/${id}`);
        }
    }
};
</script>

<style lang="scss" scoped>
.content-table {
    min-width: 100%;
    background: #fff;
    transition: 0.1s all ease-in-out;

    border-radius: 10px;
    overflow-x: scroll;
    overflow-y: visible;
    @media screen and (min-width: 1000px) {
        overflow-x: visible;
        overflow-y: visible;
    }
    .container-table-coliseum {
        min-width: 800px;
        padding: 0.5rem 1rem;
        display: grid;
        grid-template-columns: 1fr 4fr 4fr 4fr 4fr 4fr 4fr;
        text-align: center;
        @media screen and (min-width: 600px) {
            padding: 1rem 2rem;
        }

        p {
            margin: 0;
            font-size: 12px;
            transition: 0.3s all ease-in-out;
            @media screen and (min-width: 600px) {
                font-size: 14px;
            }
        }
        .icon {
            //color: red;
            margin: 0 0.2rem;
            cursor: pointer;
        }
    }
    .header-table {
        transition: 0.1s all ease-in-out;
        background: #f3f2f7;
        text-align: center;
        p {
            font-weight: 600;
        }
    }
    .body-table {
        transition: 0.1s all ease-in-out;
        border-bottom: 0.5px solid #e6e6e6;
        &:last-child {
            border-bottom: none;
        }
        .body-index {
            min-width: 30.88px;
        }
        .body-cell {
            min-width: 123.52px;
            p {
                word-wrap: break-word;
            }
            .badge {
                width: 80%;
                @media screen and (min-width: 600px) {
                    width: 50% !important;
                }
            }
        }
    }
}
.container-tournament {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    p {
        font-size: 12px !important;
        background: #fafafa;
        width: 100%;
        margin-bottom: 0.4rem !important;
        padding: 0.2rem 0.3rem;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    .tournament-link {
        color: #7367f0 !important;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        &:hover {
            font-weight: bold;
        }
    }
    .item-phone-contact {
        background: #fafafa;
        transition: 0.3s all ease-in-out;
        width: 90%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.3rem;

        margin-bottom: 0.4rem !important;
        @media screen and (min-width: 600px) {
        width: 60%;
    }
        p {
            background: #fff;
            transition: 0.3s all ease-in-out;
        }
        .tournament-link {
            padding: 0.2rem 0;
            background: transparent;
        }
        &:hover {
            p {
                font-weight: bold;
            }
        }
    }
}
.dark-layout {
    .item-phone-contact {
        background: #242b3f;
    }
}
.container-header {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    .page-btn {
        height: 32px;
        width: 32px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s all ease-in-out;
        border-radius: 32px;
        cursor: pointer;
        background: #f3f2f7;
    }
    .container-btns {
        margin: 0 0.4rem;
        width: auto;
        background: #f3f2f7;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        .active {
            background: #7367f0;
            color: #fff;
        }
    }
    .disabled {
        color: #d5d5d5;
    }
}

.dark-layout {
    .content-table {
        background: #283046;
        .header-table {
            background: #343d55;
        }
        .body-table {
            border-bottom-color: #3b4253;
        }
    }
    .container-header {
        background: #283046;
        .page-btn {
            background: #242b3d;
        }
        .container-btns {
            background: #242b3d;
            .active {
                background: #7367f0;
                color: #fff;
            }
        }
    }
}
.dark-layout {
    .container-tournament {
        p {
            background: #242b3f;
        }
    }
}
.circle {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 4px;
    margin-top: 0 !important;
}
</style>
