<template>
    <div>
        <feather-icon
            @click="selectOrganizer"
            class="icon cursor-pointer"
            icon="EditIcon"
            :size="width < 600 ? '18' : '24'"
        />
        <form-contact-brain
            v-if="openModal"
            :data="data"
            :openModal="openModal"
            @resetModal="resetModal"
            update
            :id="item.id"
        />
    </div>
</template>

<script>
import FormContactBrain from "./FormContactBrain";

export default {
    name: "EditOrganizerBrain",
    components: { FormContactBrain },
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            width: this.$store.state["app"].windowWidth,
            openModal: false,
            tournaments: [],
            organizerName: null,
            selectTournaments: [],
            data: {
                organizerName: null,
                selectTournaments: [],
                phone: null,
                phone_aux1: null,
                phone_aux2: null,
                addPhone1: false,
                addPhone2: false
            }
        };
    },
    mounted() {
        console.log('init: ', this.item);
    },

    methods: {
        resetModal() {
            this.openModal = false;
            this.$emit("refreshModal");
            this.openModal = false;
        },
        selectOrganizer() {
            this.openModal = true;
            this.data.organizerName = this.item.name;
            this.data.phone = this.item.phones.phone;
            this.item.phones.phone_aux1 ? this.data.addPhone1 = true : this.data.addPhone1 = false
            this.item.phones.phone_aux2 ? this.data.addPhone2 = true : this.data.addPhone2 = false
           
            this.data.phone_aux1 = this.item.phones.phone_aux1 
            this.data.phone_aux2 = this.item.phones.phone_aux2
            this.data.selectTournaments = this.item.tournaments.map(item => {
                return item.tournament_id;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-agregar-organizer {
    width: 200px !important;
}
.icon {
    //color: red;
    margin: 0 0.2rem;
    cursor: pointer;
}
</style>
