<template>
    <div class="pb-5">
        <div class="mt-2 mb-3 container-header">
            <!-- <div class="d-flex align-items-center justify-content-between mb-1">
                <div>
                    <div class="">
                        <p class="m-0">Mostrando 1 a 10 de 14 registros</p>
                    </div>
                </div>
                <div></div>
            </div> -->
            <div class="container-body-header">
                <div class="d-flex align-items-center">
                    <p
                        class="page-btn"
                        :class="[pageActive == 1 ? 'disabled' : null]"
                        @click="changePage(pageIn - 1)"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="20" />
                    </p>
                    <div class="container-btns">
                        <p
                            @click="changePage(num)"
                            class="page-btn"
                            :class="[pageActive == num ? 'active' : null]"
                            v-for="(num, index) in pages"
                            :key="index"
                        >
                            {{ num }}
                        </p>
                    </div>
                    <p
                        class="page-btn"
                        :class="[pageActive == totalPages ? 'disabled' : null]"
                        @click="changePage(pageIn + 1)"
                    >
                        <feather-icon icon="ChevronRightIcon" size="20" />
                    </p>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-2 mt-md-0">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar contacto"
                        v-model="campo"
                        v-on:keyup.enter="keyEnter"
                    />
                    <create-contact-brain @refreshModal="refreshModal" />
                </div>
            </div>
        </div>
        <div class="m-auto card content-table">
            <div class="container-table-coliseum header-table">
                <p>#</p>
                <p>Nombre</p>
                <p>Telefonos</p>
                <!-- <p>Torneos</p> -->
                <p>Creado por</p>
                <p>Status</p>
                <p>Opciones</p>
            </div>

            <list-contacts-brain-item v-for="(item, index) in data" :key="index" :item="item" :index="index" @refreshModal="refreshModal"/>


            <div class="text-center" v-if="data.length == 0">
                <p class="text-center mb-2 mt-2 font-medium-1">
                    Sin datos de contactos
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import contactService from "./contacts.service";
import CreateContactBrain from "./components/CreateContactBrain";
import EditContactBrain from "./components/EditContactBrain";
import { useClipboard } from "@vueuse/core";
import ListContactsBrainItem from './components/ListContactsBrainItem.vue'

export default {
    name: "ListContactsBrain",
    components: { CreateContactBrain, EditContactBrain, ListContactsBrainItem },
    data() {
        return {
            data: [],
            width: this.$store.state["app"].windowWidth,
            pageIn: 1,
            perPageIn: 50,
            campo: null,
            pages: [],
            pageActive: 1,
            totalPages: null,
            openModal: false,

        };
    },
    async mounted() {
        this.init();
    },
    methods: {
        async init() {
            const {
                data: { data, last_page, current_page }
            } = await contactService.getContactsToStatus({
                status: 0,
                campo: this.campo,
                pageIn: this.pageIn,
                perPageIn: this.perPageIn
            });

            this.pages = this.createPages(last_page);
            this.pageActive = current_page;
            this.totalPages = last_page;

            this.data = data.map(organizer => {
                return {
                    ...organizer,
                    phones: JSON.parse(organizer.phones)
                };
            });
        },
        createPages(num) {
            let pages = [];
            for (let i = 1; i <= num; i++) {
                pages.push(i);
            }
            return pages;
        },
        toTournament(id) {
            window.open(`/torneos/detalle/${id}`);
        },
        //organizerService
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.pageIn = page;
                this.init();
            }
        },
        keyEnter() {
            this.pageIn = 1;
            this.init();
        },
        doCopy(message) {
            const { copy } = useClipboard();

            copy(message);
        },
        async toggleStatus(status, id) {
            const swal = await this.showConfirmSwal();
            if (swal.isConfirmed) {
                const resp = await contactService.updateStatus({
                    status: status == 3 ? 1 : status + 1,
                    id
                });
                await this.init();
            }
        },
        refreshModal() {
            this.init();
        },


    }
};
</script>

<style lang="scss" scoped>
.content-table {
    min-width: 100%;
    background: #fff;
    transition: 0.1s all ease-in-out;

    border-radius: 10px;
    overflow-x: scroll;
    overflow-y: visible;
    @media screen and (min-width: 1000px) {
        overflow-x: visible;
        overflow-y: visible;
    }
    .container-table-coliseum {
    min-width: 800px;
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 4fr 4fr 4fr 4fr 4fr;
    text-align: center;
    @media screen and (min-width: 600px) {
        padding: 1rem 2rem;
    }

    p {
        margin: 0;
        font-size: 12px;
        transition: 0.3s all ease-in-out;
        @media screen and (min-width: 600px) {
            font-size: 14px;
        }
    }
    .icon {
        //color: red;
        margin: 0 0.2rem;
        cursor: pointer;
    }
}
    .header-table {
        transition: 0.1s all ease-in-out;
        background: #f3f2f7;
        text-align: center;
        p {
            font-weight: 600;
        }
    }
    .body-table {
        transition: 0.1s all ease-in-out;
        border-bottom: 0.5px solid #e6e6e6;
        &:last-child {
            border-bottom: none;
        }
        .body-index {
            min-width: 30.88px;
        }
        .body-cell {
            min-width: 123.52px;
            p {
                word-wrap: break-word;
            }
            .badge {
                width: 80%;
                @media screen and (min-width: 600px) {
                    width: 50% !important;
                }
            }
        }
    }
}
.container-tournament {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    p {
        font-size: 12px !important;
        background: #fafafa;
        width: 100%;
        margin-bottom: 0.4rem !important;
        padding: 0.2rem 0.3rem;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    .tournament-link {
        color: #7367f0 !important;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        &:hover {
            font-weight: bold;
        }
    }
    .item-phone-contact {
        background: #fafafa;
        transition: 0.3s all ease-in-out;
        width: 60%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.3rem;

        margin-bottom: 0.4rem !important;
        p {
            background: #fff;
            transition: 0.3s all ease-in-out;
        }
        .tournament-link {
            padding: 0.2rem 0;
            background: transparent;
        }
        &:hover {
            p {
                font-weight: bold;
            }
        }
    }
}
.dark-layout {
    .item-phone-contact {
        background: #242b3f;
    }
}
.container-header {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    @media screen and (min-width: 700px) {
        padding: 1rem;
    }
    .page-btn {
        height: 32px;
        width: 32px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s all ease-in-out;
        border-radius: 32px;
        cursor: pointer;
        background: #f3f2f7;
    }
    .container-btns {
        margin: 0 0.4rem;
        width: auto;
        background: #f3f2f7;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        .active {
            background: #7367f0;
            color: #fff;
        }
    }
    .disabled {
        color: #d5d5d5;
    }
}

.dark-layout {
    .content-table {
        background: #283046;
        .header-table {
            background: #343d55;
        }
        .body-table {
            border-bottom-color: #3b4253;
        }
    }
    .container-header {
        background: #283046;
        .page-btn {
            background: #242b3d;
        }
        .container-btns {
            background: #242b3d;
            .active {
                background: #7367f0;
                color: #fff;
            }
        }
    }
}
.dark-layout {
    .container-tournament {
        p {
            background: #242b3f;
        }
    }
}
.container-body-header {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 700px) {
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
